<template>
  <div>
    <a-page-header
      class="page-header"
      :ghost="false"
      style="margin-bottom: 16px"
    >
      <template #title>
        <a-space size="middle">
          <a-icon type="alert"></a-icon>
          <span>历史事件</span>
        </a-space>
      </template>
      <span>历史事件包含名称、严重性、触发时间等。</span>
    </a-page-header>

    <a-card :bordered="false" style="margin-bottom: 16px">
      <event-history-table
        :tools-list="['reload', 'search', 'leftTool', 'setting']"
      ></event-history-table>
    </a-card>
  </div>
</template>

<script>
import EventHistoryTable from '@/components/table/EventHistoryTable.vue'

export default {
  name: 'EventHistory',
  components: {
    EventHistoryTable
  }
}
</script>
