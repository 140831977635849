<template>
  <div>
    <list-layout
      :column-default-selected="columnDefaultSelected"
      :columns-all="columnsAll"
      :fetch-id="fetchId"
      :refresh="refresh"
      :get-table-data-func="getTableDataFunc"
      :table-operation-width="200"
      :defaultSelected="tableSelectData"
      @tableSelectData="(v) => tableSelectData = v"
      :customer-params="params"
      :tools-list="toolsList"
      :can-drag-column-width="true"
    >
      <template #leftTool>
        <datetime-range
          @ok="
            v => {
              datetimeRange = v
            }
          "
        ></datetime-range>
      </template>
      <template #name="{ slotProps }">
        <a-tooltip placement="topLeft" :title="slotProps.text">
          <a
            v-if="slotProps.record.event_source && slotProps.record.event_source.redirect_url"
            @click="redirect(slotProps.record.event_source.redirect_url)"
          >
            {{ slotProps.text }}
          </a>
          <span v-else>{{ slotProps.text }}</span>
        </a-tooltip>
      </template>
      <template #severity="{ slotProps }">
        <alert-severity-tag :severity="slotProps.text"></alert-severity-tag>
      </template>
      <template #eventSource="{ slotProps }">
        {{ slotProps.text ? slotProps.text.name : '-' }}
      </template>
      <template #service="{ slotProps }">
        {{ slotProps.text ? slotProps.text.name : '-' }}
      </template>
      <template #sourceType="{ slotProps }">
        {{ slotProps.text ? $t(`source_type.${slotProps.text}`) : '-' }}
      </template>
      <template #source="{ slotProps }">
        <a-tooltip
          v-if="slotProps.text"
          placement="topLeft"
          :title="slotProps.text.name"
        >
          <a @click="click(slotProps.record.source_type, slotProps.text.id)">
            {{ slotProps.text.name }}
          </a>
        </a-tooltip>
        <span v-else>-</span>
      </template>
      <template #description="{ slotProps }">
        {{ slotProps.text ? slotProps.text : '-' }}
      </template>
      <template #operation="{ slotProps }">
        <delete-button
          v-permission="{
            action: 'base.event.delete',
            effect: 'disabled'
          }"
          @confirm="confirm(slotProps.record.id)"
        ></delete-button>
      </template>
    </list-layout>
  </div>
</template>

<script>
import { createEventITSM } from '@/api/itsm'
import { getEventList, deleteEvent } from '@/api/event'
import { alertSeverities, sourceTypes } from '@/utils/const'
import DatetimeRange from '@/components/DatetimeRange'
import DeleteButton from '@/components/button/DeleteButton'
import ListLayout from '@/components/ListLayout'
import AlertSeverityTag from '@/components/tag/AlertSeverityTag'

export default {
  name: 'EventHistoryTable',
  props: {
    toolsList: {
      type: Array,
      default: () => []
    },
    getTableDataFunc: {
      type: Function,
      default: getEventList
    },
    fetchParams: {
      type: Object
    },
    fetchId: {
      type: String
    }
  },
  components: {
    DatetimeRange,
    DeleteButton,
    ListLayout,
    AlertSeverityTag
  },
  data () {
    return {
      datetimeRange: [],
      columnsAll: [
        {
          dataIndex: 'name',
          title: '名称',
          width: 200,
          ellipsis: true,
          scopedSlots: {
            customRender: 'name'
          }
        },
        {
          dataIndex: 'severity',
          title: '严重性',
          width: 90,
          scopedSlots: {
            customRender: 'severity'
          },
          filteredValue: [],
          filters: alertSeverities.map(value => {
            return {
              text: this.$t(`alert_severity.${value}`),
              value
            }
          }),
          filterMultiple: false
        },
        {
          dataIndex: 'event_source',
          title: '事件源',
          width: 100,
          ellipsis: true,
          scopedSlots: {
            customRender: 'eventSource'
          }
        },
        {
          dataIndex: 'service',
          title: '所属业务',
          width: 200,
          ellipsis: true,
          scopedSlots: {
            customRender: 'service'
          }
        },
        {
          dataIndex: 'source_type',
          title: '资源类型',
          width: 140,
          scopedSlots: {
            customRender: 'sourceType'
          },
          filteredValue: [],
          filters: sourceTypes.map(value => {
            return {
              text: this.$t(`source_type.${value}`),
              value
            }
          }),
          filterMultiple: false
        },
        {
          dataIndex: 'source',
          title: '资源名称',
          width: 200,
          ellipsis: true,
          scopedSlots: {
            customRender: 'source'
          }
        },
        {
          dataIndex: 'triggered_at',
          title: '触发时间',
          width: 200,
          scopedSlots: {
            customRender: 'triggeredAt'
          }
        },
        {
          dataIndex: 'description',
          title: '描述',
          width: 300,
          scopedSlots: {
            customRender: 'description'
          }
        }
      ],
      columnDefaultSelected: [
        'name',
        'severity',
        'event_source',
        'triggered_at',
        'description',
        'service',
        'source'
      ],
      tableSelectData: [],
      refresh: false
    }
  },
  computed: {
    params () {
      const obj = {}
      for (const key in this.fetchParams) {
        if (Object.hasOwnProperty.call(this.fetchParams, key)) {
          obj[key] = this.fetchParams[key]
        }
      }
      this.columnsAll.forEach(column => {
        if (Object.hasOwnProperty.call(column, 'filteredValue')) {
          if (Object.hasOwnProperty.call(obj, column.dataIndex)) {
            column.filteredValue = [obj[column.dataIndex]]
          } else column.filteredValue = []
        }
      })
      return obj
    }
  },
  methods: {
    redirect (redirectUrl) {
      window.open(redirectUrl, '_blank')
    },
    createTicket (id) {
      createEventITSM({ event_id: id }).then(res => {
        this.$message.success(res.message)
        this.refresh = !this.refresh
      })
    },
    async confirm (id = '') {
      if (id) {
        await deleteEvent(id).then(res => {
          this.$message.success(res.message)
        })
        this.tableSelectData.splice(this.tableSelectData.findIndex(item => item.key === id), 1)
      }
      this.refresh = !this.refresh
    }
  }
}
</script>
