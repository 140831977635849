import request from '@/utils/request'

const urlPrefix = '/events'

export function getEvent (id) {
  return request.get(`${urlPrefix}/${id}`)
}

export function updateEvent (id, data) {
  return request.put(`${urlPrefix}/${id}`, data)
}

export function deleteEvent (id) {
  return request.delete(`${urlPrefix}/${id}`)
}

export function getEventList (params) {
  return request.get(urlPrefix, { params })
}
